@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&family=Zen+Old+Mincho:wght@400;900&family=Zilla+Slab:wght@400;500;600&display=swap");

:root {
  --primary: #003057;
  --secondary: #6bcaba;
  --harmony: orange;
  --grey: #c3c2c7;
  --white: #e8e8f3;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
}

body {
  font-family: "Nunito", sans-serif;
  background-color: var(--white);
  color: var(--primary);
}

a {
  color: var(--primary);
}

h1 {
  font-family: "Zilla Slab", serif;
  text-align: center;
  font-weight: 600;
  font-size: 3rem;
}

h2 {
  text-align: center;
  font-weight: 700;
  font-size: 2rem;
  color: var(--harmony);
}

h3 {
  text-align: center;
  font-size: 1.75rem;
  font-weight: 700;
}

h4 {
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  color: var(--harmony);
}

h5 {
  color: var(--primary);
}

button {
  border: none;
  background-color: transparent;
}

.top {
  padding-top: 5.5rem;
}

.harmony {
  color: var(--harmony);
}

.secondary {
  color: var(--secondary);
}

.vh {
  height: 100vh;
}

.shadowFlat {
  /* border: white 1px solid; */
  box-shadow: -7px -7px 15px #fff, 7px 7px 15px #77778440;
}

.shadowConcave {
  /* border: white 1px solid; */
  box-shadow: inset -7px -7px 15px #ffffff70, inset 7px 7px 12px #77778440;
}

.shadowConvex {
  /* border: white 1px solid; */
  box-shadow: -5px -5px 15px #fff, 7px 7px 20px #77778440,
    inset -7px -7px 12px #77778440, inset 5px 5px 7px #fff;
}

.btn {
  font-size: 1.7rem;
  font-weight: 700;
  cursor: pointer;
  color: var(--primary);
}

.btn:hover {
  color: var(--secondary);
}

.btnLong {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  font-family: "Zilla Slab", serif;
  color: var(--primary);
  font-size: 2rem;
  font-weight: 700;
  padding-top: 1rem;
  cursor: pointer;
  padding-bottom: 1rem;
  transition: all 0.5s ease;
}

.dropDown {
  margin: 0 1rem;
  padding: 0 1rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.textArea::placeholder,
input::placeholder {
  color: var(--grey);
}
