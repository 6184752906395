.menu {
  position: fixed;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 30px;
  background: #e8e8f3;
  border-radius: 100%;
  cursor: pointer;
}
.menu::before,
.menu::after {
  content: "";
  background: var(--primary);
  border-radius: 5px;
  width: 30px;
  height: 5px;
  position: absolute;
  left: 16px;
  top: 27px;
  transition: 0.2s ease;
  z-index: 1;
}
.menu::before {
  transform: rotate(0deg);
}
.menu::after {
  transform: rotate(-90deg);
}
.menu.open {
  opacity: 1;
}
.menu.open::before {
  transform: rotate(45deg);
}
.menu.open::after {
  transform: rotate(-45deg);
}
.menu.open .button {
  opacity: 1;
  pointer-events: auto;
}
.menu.open .button:first-of-type {
  bottom: 40px;
  right: 70px;
  background: url("../../assets/svg/portfolio.svg") no-repeat 50%/50% #e8e8f3;
}
.menu.open .button:nth-of-type(2) {
  bottom: 80px;
  background: url("../../assets/svg/about.svg") no-repeat 50%/ 50% #e8e8f3;
  transition-delay: 0.05s;
}
.menu.open .button:last-of-type {
  bottom: 40px;
  right: -70px;
  background: url("../../assets/svg/contact.svg") no-repeat 50% 45%/50% 45%
    #e8e8f3;
  transition-delay: 0.1s;
}
.button {
  padding: 30px;
  border-radius: 50%;
  cursor: pointer;
  background: #e8e8f3;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  box-shadow: inherit;
  transition: 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28), 0.2s ease opacity,
    0.2s cubic-bezier(0.08, 0.82, 0.17, 1) transform;
}
.button:hover {
  transform: scale(1.1);
}
.button:hover {
  background: none;
}
.menu.open .button:first-of-type:hover {
  background: url("../../assets/svg/portfolio-active.svg") no-repeat 50%/50%
    #e8e8f3;
}
.menu.open .button:nth-of-type(2):hover {
  background: url("../../assets/svg/about-active.svg") no-repeat 50%/ 50% #e8e8f3;
}
.menu.open .button:last-of-type:hover {
  background: url("../../assets/svg/contact-active.svg") no-repeat 50% 45%/50%
    45% #e8e8f3;
}
.menu.open .nutton:first-of-type .portfolio-active {
  background: url("../../assets/svg/portfolio-active.svg") no-repeat 50%/50%
    #e8e8f3;
}
