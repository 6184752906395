.home-screen {
  height: 100vh;
  margin-bottom: 4rem;
  display: flex;
}

.column {
  width: 50%;
  position: relative;
}

.home-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-rounded {
  background: var(--white);
  width: calc(100% - 4rem);
  position: absolute;
  bottom: 14rem;
  left: 0;
  transform: translate(2rem, 0);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
}
