.card {
  border-radius: 1rem;
  margin: 1rem 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.title {
  font-size: 1.3rem;
}

.card-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.card-social-media {
  display: flex;
}
