@import url(https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&family=Zen+Old+Mincho:wght@400;900&family=Zilla+Slab:wght@400;500;600&display=swap);
.home-screen {
  height: 100vh;
  margin-bottom: 4rem;
  display: flex;
}

.column {
  width: 50%;
  position: relative;
}

.home-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-rounded {
  background: var(--white);
  width: calc(100% - 4rem);
  position: absolute;
  bottom: 14rem;
  left: 0;
  -webkit-transform: translate(2rem, 0);
          transform: translate(2rem, 0);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
}

.card {
  border-radius: 1rem;
  margin: 1rem 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.title {
  font-size: 1.3rem;
}

.card-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.card-social-media {
  display: flex;
}

.navLink {
  margin: 4rem 0;
  padding: 1rem;
}

.text {
  font-weight: 300;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.wrapper {
  padding: 2rem 0;
}

.heading {
  margin: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1rem;
  padding: 2rem 0;
}

.bold {
  font-weight: 700;
  margin-bottom: 1rem;
}

.rounded {
  background: var(--white);
  border: none;
  border-radius: 1rem;
  padding-left: 1rem;
  height: 4rem;
  margin: 0.5rem 0;
}

.textArea {
  font-family: "Nunito", sans-serif;
  height: 10rem;
  padding: 1rem;
}

.input {
  color: var(--primary);
}

:root {
  --primary: #003057;
  --secondary: #6bcaba;
  --harmony: orange;
  --grey: #c3c2c7;
  --white: #e8e8f3;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
}

body {
  font-family: "Nunito", sans-serif;
  background-color: #e8e8f3;
  background-color: var(--white);
  color: #003057;
  color: var(--primary);
}

a {
  color: #003057;
  color: var(--primary);
}

h1 {
  font-family: "Zilla Slab", serif;
  text-align: center;
  font-weight: 600;
  font-size: 3rem;
}

h2 {
  text-align: center;
  font-weight: 700;
  font-size: 2rem;
  color: orange;
  color: var(--harmony);
}

h3 {
  text-align: center;
  font-size: 1.75rem;
  font-weight: 700;
}

h4 {
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  color: orange;
  color: var(--harmony);
}

h5 {
  color: #003057;
  color: var(--primary);
}

button {
  border: none;
  background-color: transparent;
}

.top {
  padding-top: 5.5rem;
}

.harmony {
  color: orange;
  color: var(--harmony);
}

.secondary {
  color: #6bcaba;
  color: var(--secondary);
}

.vh {
  height: 100vh;
}

.shadowFlat {
  /* border: white 1px solid; */
  box-shadow: -7px -7px 15px #fff, 7px 7px 15px #77778440;
}

.shadowConcave {
  /* border: white 1px solid; */
  box-shadow: inset -7px -7px 15px #ffffff70, inset 7px 7px 12px #77778440;
}

.shadowConvex {
  /* border: white 1px solid; */
  box-shadow: -5px -5px 15px #fff, 7px 7px 20px #77778440,
    inset -7px -7px 12px #77778440, inset 5px 5px 7px #fff;
}

.btn {
  font-size: 1.7rem;
  font-weight: 700;
  cursor: pointer;
  color: #003057;
  color: var(--primary);
}

.btn:hover {
  color: #6bcaba;
  color: var(--secondary);
}

.btnLong {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  font-family: "Zilla Slab", serif;
  color: #003057;
  color: var(--primary);
  font-size: 2rem;
  font-weight: 700;
  padding-top: 1rem;
  cursor: pointer;
  padding-bottom: 1rem;
  transition: all 0.5s ease;
}

.dropDown {
  margin: 0 1rem;
  padding: 0 1rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.textArea::-webkit-input-placeholder, input::-webkit-input-placeholder {
  color: #c3c2c7;
  color: var(--grey);
}

.textArea:-ms-input-placeholder, input:-ms-input-placeholder {
  color: #c3c2c7;
  color: var(--grey);
}

.textArea::placeholder,
input::placeholder {
  color: #c3c2c7;
  color: var(--grey);
}

.contact h3 {
  margin-bottom: 3rem;
}

.icon-social-media {
  aspect-ratio: 1;
  height: 40px;
  margin: 0 0.5rem;
}

.pd-landing {
  height: 100vh;
  margin: 0 1rem;
  padding-top: 5.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  /* border: 1px green solid; */
}

.empty {
  height: 3.5rem;
}

.pd-heading {
  font-size: 1.2rem;
  font-weight: 700;
}

.pd-title {
  height: 8rem;
  overflow: hidden;
  font-size: 2.5rem;
}

.pd-arrow {
  position: absolute;
  right: 0;
  bottom: 2rem;
}

.pd-content {
  margin: 0 1rem;
  padding-top: 4rem;
  /* border: 1px solid red; */
}

.pd-large {
  font-size: 2rem;
  padding: 2rem 1rem;
}

.pd-success-story {
  padding: 1rem;
  margin-bottom: 2rem;
}

.iconRenderColor {
  display: flex;
  justify-content: flex-end;
}

.extra-container {
  margin: 0 1rem;
}

.extra-text {
  margin-bottom: 2rem;
}

header {
  position: fixed;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  z-index: 99;
  background: var(--white);
}
.logo {
  width: 6rem;
  transition: all 0.3s ease;
}
.logoSmall {
  width: 4rem;
}

footer {
  /* border-top: 1px solid var(--primary); */
  margin: 1rem 0;
}
.links {
  display: flex;
  justify-content: center;
}
.ext-link {
  padding: 0.5rem 1rem;
  transition: ease all 0.3s;
}
.navLink:hover {
  color: var(--secondary);
  font-weight: 700;
}
.textSm {
  font-size: 0.8rem;
  font-weight: 300;
  text-align: center;
}

.navLink:hover {
  color: var(--secondary);
  font-weight: 700;
}

.menu {
  position: fixed;
  bottom: 4rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 30px;
  background: #e8e8f3;
  border-radius: 100%;
  cursor: pointer;
}
.menu::before,
.menu::after {
  content: "";
  background: var(--primary);
  border-radius: 5px;
  width: 30px;
  height: 5px;
  position: absolute;
  left: 16px;
  top: 27px;
  transition: 0.2s ease;
  z-index: 1;
}
.menu::before {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.menu::after {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.menu.open {
  opacity: 1;
}
.menu.open::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.menu.open::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.menu.open .button {
  opacity: 1;
  pointer-events: auto;
}
.menu.open .button:first-of-type {
  bottom: 40px;
  right: 70px;
  background: url(/static/media/portfolio.6ef1ac0d.svg) no-repeat 50%/50% #e8e8f3;
}
.menu.open .button:nth-of-type(2) {
  bottom: 80px;
  background: url(/static/media/about.e1290b64.svg) no-repeat 50%/ 50% #e8e8f3;
  transition-delay: 0.05s;
}
.menu.open .button:last-of-type {
  bottom: 40px;
  right: -70px;
  background: url(/static/media/contact.dd7eab0c.svg) no-repeat 50% 45%/50% 45%
    #e8e8f3;
  transition-delay: 0.1s;
}
.button {
  padding: 30px;
  border-radius: 50%;
  cursor: pointer;
  background: #e8e8f3;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  box-shadow: inherit;
  transition: 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28), 0.2s ease opacity,
    0.2s cubic-bezier(0.08, 0.82, 0.17, 1) -webkit-transform;
  transition: 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28), 0.2s ease opacity,
    0.2s cubic-bezier(0.08, 0.82, 0.17, 1) transform;
  transition: 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28), 0.2s ease opacity,
    0.2s cubic-bezier(0.08, 0.82, 0.17, 1) transform, 0.2s cubic-bezier(0.08, 0.82, 0.17, 1) -webkit-transform;
}
.button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.button:hover {
  background: none;
}
.menu.open .button:first-of-type:hover {
  background: url(/static/media/portfolio-active.4258516b.svg) no-repeat 50%/50%
    #e8e8f3;
}
.menu.open .button:nth-of-type(2):hover {
  background: url(/static/media/about-active.a143f87e.svg) no-repeat 50%/ 50% #e8e8f3;
}
.menu.open .button:last-of-type:hover {
  background: url(/static/media/contact-active.bb607afe.svg) no-repeat 50% 45%/50%
    45% #e8e8f3;
}
.menu.open .nutton:first-of-type .portfolio-active {
  background: url(/static/media/portfolio-active.4258516b.svg) no-repeat 50%/50%
    #e8e8f3;
}

