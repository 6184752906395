footer {
  /* border-top: 1px solid var(--primary); */
  margin: 1rem 0;
}
.links {
  display: flex;
  justify-content: center;
}
.ext-link {
  padding: 0.5rem 1rem;
  transition: ease all 0.3s;
}
.navLink:hover {
  color: var(--secondary);
  font-weight: 700;
}
.textSm {
  font-size: 0.8rem;
  font-weight: 300;
  text-align: center;
}
