.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1rem;
  padding: 2rem 0;
}

.bold {
  font-weight: 700;
  margin-bottom: 1rem;
}

.rounded {
  background: var(--white);
  border: none;
  border-radius: 1rem;
  padding-left: 1rem;
  height: 4rem;
  margin: 0.5rem 0;
}

.textArea {
  font-family: "Nunito", sans-serif;
  height: 10rem;
  padding: 1rem;
}

.input {
  color: var(--primary);
}
