header {
  position: fixed;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  z-index: 99;
  background: var(--white);
}
.logo {
  width: 6rem;
  transition: all 0.3s ease;
}
.logoSmall {
  width: 4rem;
}
