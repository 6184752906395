.pd-landing {
  height: 100vh;
  margin: 0 1rem;
  padding-top: 5.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  /* border: 1px green solid; */
}

.empty {
  height: 3.5rem;
}

.pd-heading {
  font-size: 1.2rem;
  font-weight: 700;
}

.pd-title {
  height: 8rem;
  overflow: hidden;
  font-size: 2.5rem;
}

.pd-arrow {
  position: absolute;
  right: 0;
  bottom: 2rem;
}

.pd-content {
  margin: 0 1rem;
  padding-top: 4rem;
  /* border: 1px solid red; */
}

.pd-large {
  font-size: 2rem;
  padding: 2rem 1rem;
}

.pd-success-story {
  padding: 1rem;
  margin-bottom: 2rem;
}

.iconRenderColor {
  display: flex;
  justify-content: flex-end;
}
